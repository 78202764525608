<template lang="html">
  <div class="v-autocomplete">
    <div class="v-autocomplete-input-group" :class="{'v-autocomplete-selected': value}">
      <input type="text" 
            autocomplete="off"
            spellcheck="false"
            aria-haspopup="false"
            aria-autocomplete="list"
            v-model="searchText" v-bind="inputAttrs" 
            :class="[inputAttrs.class || inputClass, { 'err-border-bottom': errMsg }]"
            :placeholder="inputAttrs.placeholder || placeholder"
            :disabled="inputAttrs.disabled || disabled"
            @blur="blur" @focus="focus" @input="inputChange"
            @keyup.enter="keyEnter" @keydown.tab="keyEnter" 
            @keydown.up="keyUp" @keydown.down="keyDown">
    </div>
    <p v-if="errMsg" class="c-input__errMsg">{{ errMsg }}</p>
    <div class="v-autocomplete-list" v-if="show">
      <template v-if="internalItems.length">
        <div class="v-autocomplete-list-item" v-for="item, i in internalItems" @click="onClickItem(item)"
            v-bind:key="`key`+i"
            :class="{'v-autocomplete-item-active': i === cursor}" @mouseover="cursor = i">
          <div :is="componentItem" :item="item" :searchText="searchText"></div>
        </div>
      </template>
      <template v-else>
        <div class="v-autocomplete-list-item" style="justify-content: center;"><div>検索候補がありません</div></div>
      </template>
    </div>
  </div>
</template>

<script>
import SelectItemTemplate from '../SelectItemTemplate.vue'
import utils from './utils.js'
export default {
  name: 'v-autocomplete',
  props: {
    componentItem: { default: () => SelectItemTemplate },
    minLen: { type: Number, default: utils.minLen },
    wait: { type: Number, default: utils.wait },
    value: null,
    getLabel: {
      type: Function,
      default: item => item
    },
    items: Array,
    initItems: {default:()=>[]},
    autoSelectOneItem: { type: Boolean, default: true },
    placeholder: String,
    inputClass: {type: String, default: 'v-autocomplete-input'},
    disabled: {type: Boolean, default: false},
    inputAttrs: {type: Object, default: () => {return {}}},
    keepOpen: {type: Boolean, default: false},
    errMsg: {
      type: String,
    },
  },
  data () {
    return {
      searchText: '',
      showList: false,
      cursor: -1,
      internalItems: this.items || [],
      isValid: false,
    }
  },
  computed: {
    hasItems () {
      return !!this.internalItems.length
    },
    show () {
      return this.showList || this.keepOpen
    }
  },
  methods: {
    inputChange () {
      this.showList = true
      this.cursor = -1
      this.onSelectItem(null, 'inputChange')
      utils.callUpdateItems(this.searchText, this.updateItems)
      this.$emit('change', this.searchText)
    },
    updateItems () {
      this.$emit('update-items', this.searchText)
    },
    focus () {
      if (!this.initItems.length) {
        this.initItems = this.items || []
      }
      this.setItems(this.initItems)
      this.$emit('focus', this.searchText)
      this.showList = true
      this.cursor =  this.internalItems.map(it => it.name).indexOf(this.searchText)
      if (this.cursor > -1 && this.cursor < this.internalItems.length) {
      setTimeout( () => this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor + 3 < this.internalItems.length ? this.cursor + 3 : this.cursor].scrollIntoView({ block: 'nearest' }), 200)
      }
    },
    blur () {
      if (!this.value) this.searchText = ''
      this.$emit('blur', this.searchText)
      setTimeout( () => this.showList = false, 200)
    },
    onClickItem(item) {
      this.onSelectItem(item)
      this.$emit('item-clicked', item)
    },
    onSelectItem (item) {
      if (item) {
        this.internalItems = [item]
        this.searchText = this.getLabel(item)
        this.$emit('item-selected', item)
      } else {
        this.setItems(this.items)
      }
      this.$emit('input', item)
    },
    setItems (items) {
      this.internalItems = items || []
    },
    isSelectedValue (value) {
      return 1 == this.internalItems.length && value == this.internalItems[0] && this.items.length != 1
    },
    keyUp () {
      if (this.cursor > -1) {
        this.cursor--
        this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
      }
    },
    keyDown () {
      if (this.cursor < this.internalItems.length) {
        this.cursor++
        this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
      }
    },
    itemView (item) {
      if (item && item.scrollIntoView) {
        item.scrollIntoView(false)
      }
    },
    keyEnter () {
      if (this.showList && this.internalItems[this.cursor]) {
        this.onSelectItem(this.internalItems[this.cursor])
        this.showList = false
      }
    },
  },
  created () {
    utils.minLen = this.minLen
    utils.wait = this.wait
    this.onSelectItem(this.value)
  },
  watch: {
    items (newValue) {
      this.setItems(newValue)
      let item = utils.findItem(this.items, this.searchText, this.autoSelectOneItem)
      if (item) {
        this.onSelectItem(item)
        this.showList = false
      }
    },
    value (newValue) {
      if (!this.isSelectedValue(newValue) ) {
        this.onSelectItem(newValue)
        this.searchText = this.getLabel(newValue)
      }
    }
  }
}
</script>
<style scoped>
.err-border-bottom {
  border-bottom: 1px solid #ff0000;
}
</style>