export default {
  namespaced: true,
  state: {
    auth: null,
    token: null,
  },
  getters: {
    get(state) {
      return state.auth;
    },
    getToken(state) {
      return state.token;
    },
  },
  mutations: {
    set(state, payload) {
      state.auth = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
  },
};
