export default {
    namespaced: true,
    state: {
      searchParam: {
        wasteName: "",
        generateCompanyId: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        status: [],
        issueDateFrom: "",
        issueDateTo: "",
        generateStoreIdList: [
          {
            value: "",
          },
        ],
        disposalCompanyId: "",
        wasteType: [],
        jwnetItemTypeField:[],
        contactNo: "",
        remarks: "",
        isActive: true,
      },
      isNoCollectionDate: false,
    },
    getters: {
      get(state) {
        return state.searchParam;
      },
      isSearchDetail(state) {
        return (state.searchParam.generateStoreIdList.length && state.searchParam.generateStoreIdList[0].value )
          || state.searchParam.issueDateFrom != ""
          || state.searchParam.issueDateTo != ""
          || state.searchParam.disposalCompanyId != ""
          || state.searchParam.wasteType.length > 0
          || state.searchParam.jwnetItemTypeField.length > 0
          || state.searchParam.contactNo != ""
          || state.searchParam.remarks != ""
          || state.searchParam.isActive == false;
      },
      isNoDateShow(state) {
        return state.isNoCollectionDate;
      },
    },
    mutations: {
      set(state, payload) {
        state.searchParam = payload;
      },
      setIsNoCollectionDate(state, payload) {
        state.isNoCollectionDate = payload;
      },
    },
  };
  