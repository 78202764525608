export default {
  namespaced: true,
  state: {
    searchParam: {
      tempInputSearch: "",
      businessTypeIdList: [],
      isCBAContractList: [],
      accountStartDateFrom: "",
      accountStartDateTo: "",
      contractStatusTypeId: false,
      jwnetItemTypeSearch: [],
    },
  },
  getters: {
    get(state) {
      return state.searchParam;
    },
    isSearchDetail(state) {
      return state.searchParam.accountStartDateFrom
        || state.searchParam.accountStartDateTo
        || state.searchParam.contractStatusTypeId
        || state.searchParam.jwnetItemTypeSearch.length;
    },
  },
  mutations: {
    set(state, payload) {
      state.searchParam = payload;
    },
  },
};
