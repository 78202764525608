export default {
  namespaced: true,
  state: {
    licencePage: 1,
    searchParam: {
      wasteTypeId: 1,
      companyName: "",
      municipalityNameList: [
        {
          name: "",
        }
      ],
      licenceCityNameList: [
        {
          name: "",
        }
      ],
      targetLicenceList: [],
      licenceStatusList: [],
      jwnetItemTypeManagementList: [
        1,2
      ],
      excellentJudge: [],
      commonJwnetItemTypeIdList: [
        {
          value: "",
        }
      ],
      specialJwnetItemTypeIdList: [
        {
          value: "",
        }
      ],
    },
  },
  getters: {
    getLicencePage(state) {
      return state.licencePage
    },
    get(state) {
      return state.searchParam;
    },
  },
  mutations: {
    setLicencePage(state, payload) {
      state.licencePage = payload
    },
    set(state, payload) {
      state.searchParam = payload;
    },
  },
};
