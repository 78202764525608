<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@import "~/assets/scss/style.scss";
</style>

<script>
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  name: "App",
  methods: {
    updateMeta() {
      document.title = this.$route.meta.title;
    },
  },
  watch: {
    $route() {
      this.updateMeta();
    },
  },
  mounted() {
    this.updateMeta();
  },
};
</script>
