import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import * as filters from "./filter";
import axios from "axios";
import Zendesk from "./vue-zendesk";
import * as VeeValidate from 'vee-validate';
import { extend, localize, ValidationProvider, ValidationObserver} from 'vee-validate';
import  * as Rules from 'vee-validate/dist/rules';
// 独自実装したvee-validateのカスタムルールをインポート
import * as CustomRules from './validationCustomRules.js'
import vMessage from './validationDefaultMessage.js'
import VAutocomplete from "@/components/parts/input/vautocomplete/Autocomplete";

// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'v-autocomplete/dist/v-autocomplete.css'


Vue.config.devtools = process.env.NODE_ENV === "development";
Vue.config.productionTip = false;
axios.defaults.withCredentials = true;

Vue.use(VueCookies);
Vue.use(Zendesk, {
  key: process.env.VUE_APP_ZENDESK_KEY,
  disabled: false,
  hideOnLoad: true,
});
Vue.use(VeeValidate);
Vue.use(VAutocomplete);

Vue.component('VAutocomplete', VAutocomplete);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// バリデーションルールを適用する 
for (const rule in Rules) {
  extend(rule, Rules[rule])
}
for (const rule in CustomRules) {
  extend(rule, CustomRules[rule])
}

// メッセージを設定
localize('ja', vMessage);

// filter登録
Vue.filter("standardDateFormatJpYMDdd", filters.standardDateFormatJpYMDdd)
Vue.filter("dateFormat", filters.dateFormat);
Vue.filter("dateForDataFormat", filters.dateForDataFormat);
Vue.filter("dateJpYMFormat", filters.dateJpYMFormat);
Vue.filter("dateJpYMDFormat", filters.dateJpYMDFormat);
Vue.filter("dateJpMDFormat", filters.dateJpMDFormat);
Vue.filter("dateJpMDddFormat", filters.dateJpMDddFormat);
Vue.filter("dateJpYMDddFormat", filters.dateJpYMDddFormat);
Vue.filter("dateJpYMDddSlackFormat", filters.dateJpYMDddSlackFormat);
Vue.filter("formatFloat", filters.formatFloat);
Vue.filter("blankText", filters.blankText);
Vue.filter("shortenText", filters.shortenText);
Vue.filter("moneyText", filters.moneyText);
Vue.filter("zipCodeText", filters.zipCodeText);
Vue.filter("timeText", filters.timeText);

// グローバルなコンポーネント
Vue.component("AppHeader", () => import("~/components/layout/Header.vue"));
Vue.component("AppFooter", () => import("~/components/layout/Footer.vue"));
Vue.component("AppSideMenu", () => import("~/components/layout/SideMenu.vue"));
Vue.component("ErrorMessage", () => import("~/components/parts/ErrorMessage.vue"));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
