/**
 * 文字数チェック
 * @param max 最大文字数
 */
 const max_wordlength = {
  params: ['max'],
  message: '{max}文字以内で入力してください。',
  
  validate(value, { max }) {
    return value ? String(value).length <= max : true
  },
}

export { max_wordlength }
