import moment from "moment";
moment.locale("ja", {
  weekdays: [
    "日曜日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日",
  ],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
});

// 日付系 ------------------------------------------------------
// 日付表示形式に指定がない限りは、こちらが標準的に採用される形式となる。
export function standardDateFormatJpYMDdd(value) {
  if (!value) return "-";
  return moment(value).format("YYYY/M/D(ddd)");
}

export function dateFormat(value) {
  return moment(value).format("YYYY/MM/DD");
}
export function dateForDataFormat(value) {
  return moment(value).format("YYYY-MM-DD");
}

export function dateJpYMFormat(value) {
  return moment(value).format("YYYY年M月");
}

export function dateJpYMDFormat(value) {
  if (!value) return "-";
  return moment(value).format("YYYY年M月D日");
}

export function dateJpMDFormat(value) {
  return moment(value).format("M月D日");
}

export function dateJpMDddFormat(value) {
  if (!value) return "-";
  return moment(value).format("M月D日(ddd)");
}

export function dateJpYMDddFormat(value) {
  if (!value) return "-";
  return moment(value).format("YYYY年M月D日(ddd)");
}

export function dateJpYMDddSlackFormat(value) {
  if (!value) return "-";
  return moment(value).format("YYYY/MM/DD(ddd)");
}

// 数字系 ------------------------------------------------------
export function formatFloat(value) {
  return Number(value) === value && value % 1 === 0 ? value.toFixed(1) : value;
}

// テキスト系 ------------------------------------------------------
export function blankText(value) {
  if (!value || String(value).length === 0) {
    return "-";
  } else {
    return value;
  }
}

export function shortenText(value) {
  if (value && value.length > 20) {
    return value.substring(0, 19) + "...";
  } else {
    return value;
  }
}

export function moneyText(value) {
  if (!value) return "-";
  return Number(String(value)).toLocaleString() + '円';
}

export function zipCodeText(value) {
  if (!value) return "-";
  return '〒' + value.slice(0, 3) + '-' + value.slice(3, value.length);
}

// 時間系 ------------------------------------------------------
export function timeText(value) {
  if (!value) return "-";
  return moment(value).format("HH:mm");
}