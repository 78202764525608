export default {
  namespaced: true,
  state: {
    searchParam: {
      priorConsultationNo: "",
      targetMunicipalityName: "",
      generateCompanyId: "",
      jwnetItemTypeId: "",
      routeName: "",
      collectCompanyId: "",
      disposalCompanyId: "",
      priorConsultationStartDateFrom: "",
      priorConsultationStartDateTo: "",
      priorConsultationEndDateFrom: "",
      priorConsultationEndDateTo: "",
      isOveringAlert: "",
    }
  },
  getters: {
    get(state) {
      return state.searchParam;
    },
    isSearchDetail(state) {
      return state.searchParam.priorConsultationStartDateFrom
        || state.searchParam.priorConsultationStartDateTo
        || state.searchParam.priorConsultationEndDateFrom
        || state.searchParam.priorConsultationEndDateTo
        || state.searchParam.isOveringAlert
    },
  },
  mutations: {
    set(state, payload) {
      state.searchParam = payload;
    }
  }
}