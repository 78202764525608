export default {
  namespaced: true,
  state: {
    manifestProgressPage: 1,
    searchParam: {
      wasteName: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        status: [],
        collectReportSearchType: "",
        disposalReportSearchType: "",
        finalDisposalReportSearchType: "",
        isMine: true,
        generateStoreIdList: [null],
        generateStoreList: [], //選択した排出事業者に紐づく排出事業場の選択肢(ページ移動するタイミング、維持するため、送信しない)
        collectCompanyId: "",
        disposalCompanyId: "",
        disposalSiteId: "",
        wasteType: [],
        jwnetItemTypeField: [],
        termDateType: [],
        reportDateFrom: "",
        reportDateTo: "",
        registDateFrom: "",
        registDateTo: "",
        contactNo: "",
        remarks: "",
        manifestLabel: "",
        isActive: true, 
    },
  },
  getters: {
    getManifestProgressPage(state) {
      return state.manifestProgressPage
    },
    get(state) {
      return state.searchParam;
    },
    isSearchDetail(state) {
      return (state.searchParam.generateStoreIdList && state.searchParam.generateStoreIdList[0])
      || state.searchParam.generateStoreIdList.length > 1
      || state.searchParam.collectCompanyId != ""
      || state.searchParam.disposalCompanyId != ""
      || state.searchParam.disposalSiteId != ""
      || state.searchParam.wasteType.length > 0
      || (state.searchParam.jwnetItemTypeField && state.searchParam.jwnetItemTypeField.length > 0)
      || state.searchParam.termDateType.length > 0
      || state.searchParam.reportDateFrom != ""
      || state.searchParam.reportDateTo != ""
      || state.searchParam.registDateFrom != ""
      || state.searchParam.registDateTo != ""
      || state.searchParam.contactNo != ""
      || state.searchParam.remarks != ""
      || state.searchParam.manifestLabel != ""
      || state.searchParam.isActive == false;
    },
  },
  mutations: {
    set(state, payload) {
      state.searchParam = payload;
    },
    setManifestProgressPage(state, payload) {
      state.manifestProgressPage = payload
    },
  },
};
