export default {
  namespaced: true,
  state: {
    menu: [],
  },
  getters: {
    get(state) {
      return state.menu;
    },
  },
  mutations: {
    set(state, payload) {
      state.menu = payload;
    },
  },
};
