// アクセス制限名
export const ROLE = {
  CBA: "cba",
  GENERATOR: {
    ALL: "generator",
    ADMIN: "generator.admin",
    HIGH_CLASS: "generator.high_class",
    CONTRACTOR: "generator.contractor",
  },
  COLLECTOR: {
    ALL: "collector",
    ADMIN: "collector.admin",
    PROXY: "collector.proxy",
  },
  DISPOSER: {
    ALL: "disposer",
    ADMIN: "disposer.admin",
  },
};
