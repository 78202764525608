export default {
  namespaced: true,
  state: {
    searchParam: {
      keySearch: "",
      status: [],
      accountStartDateFrom: "",
      accountStartDateTo: "",
      cbaContractEndDateFrom: "",
      cbaContractEndDateTo: "",
      isAutoUpdate: [],
    },
  },
  getters: {
    get(state) {
      return state.searchParam;
    },
    isSearchDetail(state) {
      return state.searchParam.accountStartDateFrom
        || state.searchParam.accountStartDateTo
        || state.searchParam.cbaContractEndDateFrom
        || state.searchParam.cbaContractEndDateTo
        || state.searchParam.isAutoUpdate.length;
    },
  },
  mutations: {
    set(state, payload) {
      state.searchParam = payload;
    },
  },
};
