export default {
  namespaced: true,
  state: {
    searchParam: {
      subject: "", // 件名
      memberId: null, // 担当者
      collectCompanyId: [null], // 運搬事業者
      disposalCompanyId: [null], //処分事業者
      createDateFrom: "", // 登録日
      createDateTo: "", // 登録日
      nextUpdateDateFrom: "", // 次回更新日
      nextUpdateDateTo: "", // 次回更新日
      isSetFile: false, // 添付ファイル有無
    }
  },
  getters: {
    get(state) {
      return state.searchParam;
    },
    isSearchDetail(state) {
      return state.searchParam.createDateFrom
        || state.searchParam.createDateTo
        || state.searchParam.nextUpdateDateFrom
        || state.searchParam.nextUpdateDateTo
        || state.searchParam.isSetFile
    },
  },
  mutations: {
    set(state, payload) {
      state.searchParam = payload;
    }
  }
}