export default {
  namespaced: true,
  state: {
    routePage: 1,
    searchParam: {
      routeNameList: [],
      routeNameSearchText: "",
      routeIdList: [],
      routeNoSearchText: "",
      wasteNameList: [],
      wasteNameSearchText: "",
      entrustId: "",
      generateCompanyNameList: [null],
      generateStoreNameList: [null],
      collectCompanyId: "",
      collectLicenceFrom: "",
      collectLicenceTo: "",
      disposalCompanyId: "",
      disposalSite: "",
      disposalLicenceFrom: "",
      disposalLicenceTo: "",
      wasteTypeIdList: [],
      industrialWasteJwnetTypeIdList: [],
      manifestTypeIdList: [],
      changeStoreTypeIdList: [], // 事業場任意設定区分
      labelSettingTypeIdList: [], // ラベル設定有区分
      isOnlyFavorite: false,
      isIncludingInvalid: true,
      generateCompanyIndex: 1,
      generateStoreIndex:1
    }
  },
  getters: {
    getRoutePage(state) {
      return state.routePage
    },
    get(state) {
      return state.searchParam;
    },
    isSearchDetail(state) {
      return state.searchParam.entrustId
        || (state.searchParam.generateCompanyNameList && state.searchParam.generateCompanyNameList[0])
        || state.searchParam.generateCompanyNameList.length > 1
        || (state.searchParam.generateStoreNameList && state.searchParam.generateStoreNameList[0])
        || state.searchParam.generateStoreNameList.length > 1
        || state.searchParam.collectCompanyId
        || state.searchParam.disposalCompanyId
        || state.searchParam.disposalSite
        || state.searchParam.collectLicenceFrom
        || state.searchParam.collectLicenceTo
        || state.searchParam.disposalLicenceFrom
        || state.searchParam.disposalLicenceTo
        || state.searchParam.wasteTypeIdList.length != 0
        || state.searchParam.manifestTypeIdList.length != 0
        || state.searchParam.industrialWasteJwnetTypeIdList.length != 0
        || state.searchParam.changeStoreTypeIdList.length != 0
        || state.searchParam.labelSettingTypeIdList.length != 0
        || state.searchParam.isOnlyFavorite
        || !state.searchParam.isIncludingInvalid
    },
  },
  mutations: {
    setRoutePage(state, payload) {
      state.routePage = payload
    },
    set(state, payload) {
      state.searchParam = payload;
    }
  }
}
