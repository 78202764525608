export default {
  namespaced: true,
  state: {
    manifestReservePage: 1,
    searchParam: {
      wasteName: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        isMine: true,
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        status: [],
        generateStoreIdList: [null],
        generateStoreList: [], //選択した排出事業者に紐づく排出事業場の選択肢(ページ移動するタイミング、維持するため、送信しない)
        collectCompanyId: "",
        disposalCompanyId: "",
        disposalSiteId: "",
        wasteType: [],
        jwnetItemTypeField: [],
        contactNo: "",
        remarks: "",
        manifestLabel: "",
        isActive: true,
    },
    isNoCollectionDate: false,
  },
  getters: {
    getManifestReservePage(state) {
      return state.manifestReservePage
    },
    get(state) {
      return state.searchParam;
    },
    isSearchDetail(state) {
      return state.searchParam.cbaItemId != ""
      || state.searchParam.jwnetItemTypeId != ""
      || state.searchParam.routeId != ""
      || state.searchParam.routeName != ""
      || state.searchParam.status.length > 0
      || (state.searchParam.generateStoreIdList && state.searchParam.generateStoreIdList[0])
      || state.searchParam.generateStoreIdList.length > 1
      || state.searchParam.collectCompanyId != ""
      || state.searchParam.disposalCompanyId != ""
      || state.searchParam.disposalSiteId != ""
      || state.searchParam.wasteType.length > 0
      || state.searchParam.jwnetItemTypeField.length > 0
      || state.searchParam.contactNo != ""
      || state.searchParam.remarks != ""
      || state.searchParam.manifestLabel != ""
      || state.searchParam.isActive == false;
    },
    isNoDateShow(state) {
      return state.isNoCollectionDate;
    },
  },
  mutations: {
    set(state, payload) {
      state.searchParam = payload;
    },
    setIsNoCollectionDate(state, payload) {
      state.isNoCollectionDate = payload;
    },
    setManifestReservePage(state, payload) {
      state.manifestReservePage = payload
    },
  },
};
