export default {
  namespaced: true,
  state: {
    entrustPage: 1,
    searchParam: {
      wasteName: "",
      generateCompanyId: "",
      generateFactoryId: "",
      generateStoreList: [],
      collectCompanyId: "",
      disposalCompanyId: "",
      disposalSiteId: "",
      licenceNo: "",
      contractSearchStatus: [],
      isAutoUpdate: [],
      contractEndDateFrom: "",
      contractEndDateTo: "",
      wasteTypeIdList: [],
      jwnetItemTypeFieldList: [],
      manifestTypeIdList: [],
    },
  },
  getters: {
    getEntrustPage(state) {
      return state.entrustPage
    },
    get(state) {
      return state.searchParam;
    },
    isSearchDetail(state) {
      return state.searchParam.collectCompanyId
        || state.searchParam.disposalCompanyId
        || state.searchParam.disposalSiteId
        || state.searchParam.licenceNo
        || state.searchParam.contractSearchStatus.length
        || state.searchParam.isAutoUpdate.length
        || state.searchParam.contractEndDateFrom
        || state.searchParam.contractEndDateTo
        || state.searchParam.wasteTypeIdList.length
        || state.searchParam.jwnetItemTypeFieldList.length
        || state.searchParam.manifestTypeIdList.length;
    },
    isSearchDetailForCollector(state) {
      return state.searchParam.collectCompanyId
        || state.searchParam.disposalCompanyId
        || state.searchParam.licenceNo
        || state.searchParam.contractSearchStatus.length
        || state.searchParam.isAutoUpdate.length
        || state.searchParam.contractEndDateFrom
        || state.searchParam.contractEndDateTo
        || state.searchParam.wasteTypeIdList.length
        || state.searchParam.jwnetItemTypeFieldList.length
        || state.searchParam.manifestTypeIdList.length;
    },
  },
  mutations: {
    set(state, payload) {
      state.searchParam = payload;
    },
    setEntrustPage(state, payload) {
      state.entrustPage = payload
    },
  },
};
