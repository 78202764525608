import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth";
import userInfo from "./userInfo";
import menu from "./menu";
import searchParamManifestsAll from "./searchParam/manifestsAll";
import searchParamManifestsReserve from "./searchParam/manifestsReserve";
import searchParamManifestsProgress from "./searchParam/manifestsProgress";
import searchParamManifestsComplete from "./searchParam/manifestsComplete";
import searchParamOperate from "./searchParam/operate";
import searchParamEntrust from "./searchParam/entrust";
import searchParamLicence from "./searchParam/licence";
import searchParamGeneratorContract from "./searchParam/generatorContract";
import searchParamRoute from "./searchParam/route";
import searchParamPrior from "./searchParam/prior";
import searchParamManifestsCollectReportBefore from "./searchParam/searchParamManifestsCollectReportBefore";
import searchParamManifestsCollectReportAfter from "./searchParam/searchParamManifestsCollectReportAfter";
import searchParamShareinfo from "./searchParam/shareinfo";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    userInfo,
    menu,
    searchParamManifestsAll,
    searchParamManifestsReserve,
    searchParamManifestsProgress,
    searchParamManifestsComplete,
    searchParamManifestsCollectReportBefore,
    searchParamManifestsCollectReportAfter,
    searchParamOperate,
    searchParamEntrust,
    searchParamLicence,
    searchParamGeneratorContract,
    searchParamRoute,
    searchParamPrior,
    searchParamShareinfo
  },
});
