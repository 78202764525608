import {DOMAIN} from "../const";

export default {
  namespaced: true,
  state: {
    user: {},
    isCollectProxy: false,
    isDisposalProxy: false
  },
  getters: {
    get(state) {
      return state.user;
    },
    isCba(state) {
      return state.user.userTypeId >= 10 && state.user.userTypeId < 20;
    },
    isGenerator(state) {
      return state.user.userTypeId >= 20 && state.user.userTypeId < 30;
    },
    isCollector(state) {
      return state.user.userTypeId >= 30 && state.user.userTypeId < 40;
    },
    isDisposer(state) {
      return state.user.userTypeId >= 40 && state.user.userTypeId < 50;
    },
    isHighClass(state) {
      return state.user.userTypeId === DOMAIN.USER_TYPE.CBA
        || state.user.userTypeId === DOMAIN.USER_TYPE.GENERATE_CONTRACTOR
        || state.user.userTypeId === DOMAIN.USER_TYPE.GENERATE_COMPANY;
    },
    isLowClass(state) {
      return state.user.userTypeId === DOMAIN.USER_TYPE.GENERATE_FACTORY
      || state.user.userTypeId === DOMAIN.USER_TYPE.GENERATE_STORE;
    },
    isAdmin(state) {
      return state.user.accountTypeId === DOMAIN.ACCOUNT_TYPE.ADMIN;
    },
    isGenerateContractor(state) {
      return state.user.userTypeId === DOMAIN.USER_TYPE.GENERATE_CONTRACTOR;
    },
    isGenerateCompany(state) {
      return state.user.userTypeId === DOMAIN.USER_TYPE.GENERATE_COMPANY;
    },
    isCollectProxy(state) {
      return (state.user.userTypeId >= 30 && state.user.userTypeId < 40) && state.isCollectProxy;
    },
    isDisposalProxy(state) {
      return (state.user.userTypeId >= 40) && state.isDisposalProxy
    }
  },
  mutations: {
    set(state, payload) {
      state.user = payload;
      state.isCollectProxy = false;
    },
    setCollectProxy(state, payload) {
      state.isCollectProxy = payload;
    },
    setDisposalProxy(state, payload) {
      state.isDisposalProxy = payload;
    }
  },
};
